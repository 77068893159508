.payText
{
    
    font-size: 13px !important;
    font-weight: bold !important;
    color: #333333;
    padding-left: 1%;
}
.Mui-checked
{
    color: #333333 !important;
}
.radioText
{
    font-size: 20px !important;
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    padding-left: 5px !important;
}
.checkIcon
{
    color: white !important;
    background-color: #47C9D9 !important;
    font-size: 20px !important;
    margin-left: '-13px' !important;
}

.card {
    background-color: white;
    padding: 5px;
    padding-top: 10px;
    border-radius: 5px;
    width: 100%;
    height: 30px;
    border: 0.5px solid #afafaf;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  }
  .pay-button{
    padding: 0.7rem 2rem;
    width: 100%;
    margin: 1rem 0;
    color: white;
    font-weight: bold;
    font-size: medium;
    background-color: #556cd6;
    border: 0;
    border-radius: 5px;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    transition: box-shadow 500ms;
    cursor: pointer;
  }
  .pay-button:disabled{
    background-color: #afafaf;
    box-shadow: none;
    cursor: default;
  }
  .pay-button:disabled:hover{
    box-shadow: none;
  }
  .pay-button:hover{
    box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
  }