.userBox
{
    padding-left: 4em;
    padding-right: 4em;
}
.user-cancel-icon{
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 1;
}