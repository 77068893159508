body{
    font-family: 'Poppins', sans-serif !important;
}
.homeBackground {
    background: url(../../assets/images/main_banner.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    height: 100%;
}


.homeHeader{
    display: flex;
    height:auto ;
    flex-direction:row;
    background-color:#47C9D9;
    justify-content:end;
    align-items:center;
     padding-left: 3%;
     padding-right: 5%;
}

.siteHeader{
    display: flex;
    height:auto ;
    flex-direction:row;
    background-color:#47C9D9;
    justify-content:end;
    align-items:center;
     padding-left: 3%;
     padding-right: 5%;
}
.mainHeader{
    position: absolute;
    background-color: transparent;
    padding: 10px 0px 5px 0px;
    width: 85%;
    margin: 0px auto;
    z-index: 1;
    border-bottom: 2px dashed #fff;
}

.bg-gray {
    background-color: #313536;
}
.sub-box {
    display: flex;
    justify-content: flex-start;
    // background-color: #FEF8EC;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 100px;
    padding: 50px 20px 15px 20px;
    margin: 15px 0px;
    // border: 2px solid #B87B2A;
    /* width: 100%; */
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
}
#sec-con {
  
}

.sub-box .imges {
  width: 80px;
  height: 80px;
}

.sub-box .imges img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.sec2-content {
    margin-left: 10px;
    width: 100%;
    margin-top: 0px;
}
.sec2-content h2 {
    // position: relative;
    // margin: 0px;
    // margin-bottom: 5px;
    font-size: 37px;
    color: #47C9D9;
    font-weight: 600 !important;
    margin-top: 0px;
}
.sec2-content p {
    color: #FFF;
    font-size: 19px;
    margin: 25px 0px 35px 0px;
    font-weight: normal !important;
    width: 340px;
}

.sub-box-3 .imges-3{
    width: 50px;
}
.sub-box-3 h4{
    font-size: 20px;
    margin-top: 5px;
}
.sub-box-3 p{
    font-size: 14px;
}
.sub-box-3{
   
    padding: 20px;
    border: 2px solid #ecba37;
    border-radius: 20px;
    margin: 10px;
}
.sub-box-3:hover{
background-color: #ecba37;
color:#000;
}
.text-center{
    text-align: center;
}
.sec-3-head{
    margin-bottom: 25px;
    margin-top: 50px !important;
    text-align: center;
    font-size: 25px;
}
.why-chooseus{
    position: relative;
    overflow: hidden;
    padding: 40px 0px;
}
.perfect-ride{
    position: relative;
    overflow: hidden;
    padding: 40px 0px;
    z-index: 1;
}
.bg-secondary{
    background-color: #FAB104;
    color: #fff;
  
}
.empty-back {
    position: absolute;
    right: 0;
    top: 0px;
    width: 700px;
    height: 420px;
    transform: rotateZ(115deg);
    background: #ecba37;
}
.pre_head{
    font-size: 20px;
    color: #ecba37;
    margin: 0;
}
.section_head{
    font-size: 32px;
    margin: 0;
    margin-bottom: 10px;

}
.car-img {
    position: relative;
    height: 240px;
    position: relative;
    margin-top: 25px;
}
.car-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

// my changes
.sec2-imgsec{
    width: 780px;
    height: auto;
    position: absolute;
    top: -20px;
    right: -90px;
}
.sec2-imgsec img{
    width: 100%;
}
.read-more{
    margin-bottom: 50px;
}
.read-more a.readmore-btn{
    border-radius: 5px;
    padding: 15px 50px;
    background: #47C9D9;
    border: none;
    margin-bottom: 0px;
    margin-top: 10px;
    color: #ffffff;
    text-decoration: none;
}
.read-more button a:hover{
    background-color: #47C9D9;
}
// fleet
.bg-white{
    padding: 60px 0px;
}
.bg-white .sec-title {
        text-align: center;
        font-size: 30px;
        font-weight: 600 !important;
}
.bg-white .m-t-high{
    margin-top: 200px;
}
.mar-top-tit{
    margin-bottom: 200px;
    padding-top: 80px;
}
.s-b{
    display: flex;
    justify-content: space-between;
}
.squre {
    background: #47C9D9;
    margin: 10px;
    position: relative;
    max-width: 30% !important;
    margin: 0px auto !important;
    padding: 10px;
}
.box-active.squre{
    background-color: #FFD600;
}
.sec3-car {
    position: absolute;
    top: -170px;
}
.sec3-content {
    margin-top: 130px;
}
.sec3-content h3{
    color: #000;
    font-size: 25px;
    font-weight: 600 !important;
    text-align: center;
}
.sec3-content p{
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    margin-top: 20px;
}
.fleet-btn{
    width: 180px;
    margin: 0px auto;
}
.fleet-btn a{
    margin: 40px auto;
    padding: 15px 45px;
    border: none;
    color: #fff;
    background: #000;
    border-radius: 10px;
    display: flex;
    align-items: center;
    text-decoration: none;
}
.fleet-btn a:hover{
    background-color: #000;
}
.box-active #triangle-down{
    position: absolute;
    left: 0;
    bottom: -80px;
    height: 0;
    border-left: 192px solid transparent;
    border-right: 190px solid transparent;
    /* border-top: 80px solid #FFD600; */
}
.box-active #triangle-down .emm{
    position: absolute;
    left: -192px;
    bottom: 0;
    height: 0;
    border-left: 210px solid transparent;
    border-right: 210px solid transparent;
    border-top: 80px solid #FFD600;
}
#triangle-down{
    position: absolute;
    left: 0;
    bottom: -80px;
    height: 0;
    border-left: 192px solid transparent;
    border-right: 190px solid transparent;
    /* border-top: 80px solid #47C9D9; */
}
.emm {
    position: absolute;
    left: -192px;
    bottom: 0;
    height: 0;
    border-left: 225px solid transparent;
    border-right: 180px solid transparent;
    border-top: 80px solid #47C9D9;
}
.sec3-car img{
    width: 100%;
}
.box-active.squre{
    background-color: #FFD600;
}

.box-active #triangle-down{
    position: absolute;
    left: 0;
    bottom: -80px;
    height: 0;
    border-left: 190px solid transparent;
    border-right: 190px solid transparent;
    /* border-top: 80px solid #FFD600; */
}
.box-active #triangle-down .emm{
    position: absolute;
    left: -190px;
    bottom: 0;
    height: 0;
    border-left: 225px solid transparent;
    border-right: 180px solid transparent;
    border-top: 80px solid #FFD600;
}
.squre .box-active > .sec3-content > .fleet-btn > button{
    background-color: #47C9D9;
}
.unique-top{
    top: -130px;
}
// world class
.bg-gray.sec-m-t,.mar-row{
    margin-top: 40px;
}
.sec-m-t{
    padding: 15px 0px 50px 0px !important;
}
.bg-gray{
    background: #313536;
    padding: 50px 0px;
}
.sec4-imag {
    border: 20px solid #fff;
    border-radius: 10px;
    overflow: hidden;
    height: 400px;
    width: 90%;
}
.sec4-imag img{
    vertical-align: middle;
    width: 100%;
    object-fit: cover;
    height: inherit;
}
.sec4-content h2{
    color: #47C9D9;
    font-size: 37px;
    line-height: 60px;
    font-weight: 600 !important;
}
.sec4-content h2 span{
    color: #fff;
    font-size: 37px;
}
.sec4-content p{
    color: #FFF;
    font-size: 18px;
    font-weight: normal;
    padding-right: 30px;
    margin: 15px 0px;
}
.world-w-btn{
    margin-top: 45px;
}
.sec4-content a{
    padding: 15px 50px;
    border: none;
    color: #ffffff;
    border-radius: 5px;
    background: #47C9D9;
    box-shadow: -1px 1px 15px 0px rgba(0, 0, 0, 0.25);
    text-decoration: none;
}
.world-w-btn button a{
    background-color: #47C9D9;
}
.world-w-btn button a:hover{
    background-color: #47C9D9;
}
// why choose
.w-c-h{
    padding-bottom: 80px;
    text-align: center;
    font-weight: 600 !important;
    font-size: 30px;
}
.m-t-60{
    margin-top: 60px;
}
.bg-white > .container > .s-b.m-t-60 > .box-icon{
    border-radius: 8px;
    background: #FFF;
    box-shadow: 4px 2px 13px 0px rgba(0, 0, 0, 0.25);
    margin: 0px auto;
    max-width: 28.333333%;
    padding: 40px;
}
.box-icon {
    display: flex;
    flex-direction: column !important;
    align-items: center;
}
.box-icon h6 {
    font-size: 23px;
    font-weight: 600;
    margin: 20px 0px;
}
.box-icon p {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
}
.main-head{
    text-align: left;
    padding:  80px 0px 0px 0px;;
}
.gba-hide{
    display: none;
}
p{
    font-family: 'Poppins', sans-serif !important;
}
.srh-b-h{
    position: absolute;
    top: 10px;
}
.bmw-one{
    position: absolute;
    top: -100px;
}
.car-two{
    position: absolute;
    top: -130px;
    width: 370px;
    height: auto;
    left: 0px;
}
.point-h{
    min-height: 30px !important;
    margin-left: 30px
}
.MuiFormGroup-root .MuiFormControl-root.MuiTextField-root{
    border-radius: 10px !important;
}


@media screen and ( max-width:900px){
    .emm {
        border-left: 190px solid transparent !important;
        border-right: 182px solid transparent !important;
    }
    .sec3-content {
        margin-top: 30px !important;
    }
    .sec2-content h2 {
        font-size: 30px;
    }
    .srh-b-h {
        position: unset; 
        top: 10px;
    }
    .box-active.squre{
        margin-top: 190px !important;
    }
    .triangle-down{
        display: none;
    }
    .squre{
        max-width: 100% !important;
        margin-top: 190px !important;
    }
    .first-square{
        margin-top: 130px !important;
    }
    .bg-white > .container > .s-b.m-t-60 > .box-icon{
        margin-top: 30px;
        max-width: 100%;
    }
    .box-active #triangle-down{
        display: none;
    }
    #triangle-down{
        display: none;
    }
    .bmw-one{
        top: -80px;
        left: 140px;
    }
    .car-two{
        top: -140px !important;
        left: 130px;
    }
    .unique-top{
        top: -140px !important;
        left: 120px;
    }
    .sec2-imgsec{
        width: 670px;
        top: -130px;
        right: -70px;
    }
    .mar-top-tit{
        margin-bottom: 10px;
        margin-top: 150px;
    }
    .bg-white.fleet .m-t-high {
        margin-top: 0px;
    }
    .mainHeader{
        width: 75%;
    }
    .Home-srh-par{
        margin-top: 100px;
    }
    .Home-srh-f{
        align-content:baseline !important;
    }
    .sec2-content{
        margin-left: 0px;
    }
    .sub-box{
        text-align: center;
    }
    .sec2-content p{
        text-align: center;
        width: 100%;
    }
    .homeBackground{
        height: 800px;
    }
}
@media screen and ( max-width:480px){

    .heading{
        font-size: 22px;
    margin-top: 100px;
    }

    .empty-back{
        z-index: -1;
        top: auto;
        bottom: 0px;
        right: -20%;
        left: auto;
        transform: rotateZ(157deg);
        height: 255px;
    }
    // my changes
    .bg-white .m-t-high {
        margin-top: 0px;
    }
    .mar-top-tit {
        margin-top: 100px !important;
        margin: 0px;
        padding: 0px;
    }
    .s-b {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .squre {
        margin-bottom: 80px;
    }
    .sec3-car {
        position: unset;
        top: -170px;
        margin: auto;
    }
    .sec3-content {
        margin-top: 0px;
    }
    .box-active #triangle-down .emm {
        border-left: 189px solid transparent;
    }
    .emm {
        position: absolute;
        left: -192px;
        bottom: 0;
        height: 0;
        border-left: 200px solid transparent;
        border-right: 170px solid transparent;
        border-top: 80px solid #47C9D9;
    }
    .box-active #triangle-down .emm {
        border-left: 194px solid transparent;
    }
    // why choose
    .box-icon {
        margin: 20px 10px;
    }
    .s-b.m-t-60{
        flex-direction: column;
    }
    .bg-white > .container > .s-b.m-t-60 > .box-icon{
        max-width: 90%;
        margin-top: 50px;
    }
    .w-c-h{
        padding-bottom: 0px;
    }
    .bg-white.fleet > div > .m-t-high > .squre.first-square{
        margin-top: 20px !important;
    }
    .searchbtn-holder button.MuiButtonBase-root.MuiButton-root.MuiButton-filledPrimary.btn-search{
        top: 0px;
        left: 0px;
    }
    .logo img{
        width: 100%;
        height: 100%;
    }
    .mainHeader{
        width: 95%;
    }
    .mar-r-l {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
    .reg-u-t {
        position: relative;
        top: -10px;
    }
    .reg-u-t > div > p{
        color: #000;
    }
    .reg-cus{
        left: -70px;
    }
    .reg-h-con{
        left: -75px;
    }
    .reg-com-h-con{
        left: -70px;
    }
    .search-box{
        margin-top: 100px !important;
    }
}
@media screen and ( max-width:768px){
    .car-two, .bmw-one, .unique-top{
        position: unset;
        margin: auto;
    }
    button.MuiButtonBase-root.MuiTab-root{
        border-radius: 8px;
    }
    .point-h{
        margin-left: 0px;
        margin-bottom: 10px;
    }
}
@media screen and ( min-width:1200px){
    header.header-navbar .navbar-menu span p.inactive{
        font-size: 14px;
    }
    .sec2-imgsec{
        width: 850px;
        top: 60px;
        right: -90px;
    }
    .car-two{
        width: 360px;
    }
    .unique-top{
        width: 330px;
    }
    .box-icon p{
        font-size: 15px;
    }
    .box-icon h6{
        font-size: 20px;
    }
    .emm {
        border-left: 175px solid transparent;
        border-right: 175px solid transparent;
    }
    .box-active #triangle-down .emm{
        border-left: 170px solid transparent;
        border-right: 183px solid transparent;
    }
    // 125%
    .main-head{
        padding: 170px 0px 20px 0px;
    }
     
}
@media screen and ( max-width:1300px){
    .emm {
        border-left: 163px solid transparent;
        border-right: 152px solid transparent;
    }
    .box-active #triangle-down .emm {
        border-left: 150px solid transparent;
        border-right: 165px solid transparent;
    }
    .sec3-content {
        margin-top: 100px;
    }
    .bmw-one{
        width: 290px;
        top: -80px;
    }
    .car-two {
        width: 310px;
        top: -100px;
    }
    .unique-top {
        width: 280px;
        top: -100px;
    }
}


@media screen and ( min-width:1370px){
    .emm {
        border-left: 180px solid transparent;
        border-right: 180px solid transparent;
    }
}

@media screen and ( min-width:1400px){
    .emm {
        border-left: 199px solid transparent;
        border-right: 200px solid transparent;
    }
    .box-active #triangle-down .emm {
        border-left: 194px solid transparent;
        border-right: 205px solid transparent;
    }
    .car-two {
        width: 380px;
    }
    .unique-top {
        width: 360px;
    }
    .bmw-one {
        width: 360px;
    }
}

@media screen and ( min-width:1600px){
    .emm {
        border-left: 228px solid transparent;
        border-right: 228px solid transparent;
    }
    .box-active #triangle-down .emm {
        border-left: 225px solid transparent;
        border-right: 230px solid transparent;
    }
    .car-two {
        width: 440px;
    }
    .unique-top {
        width: 410px;
    }
    .bmw-one{
        width: 410px;
    }
}
// @media screen and ( max-width:1490px){
//     .emm {
//         border-left: 198px solid transparent;
//         border-right: 198px solid transparent;
//     }
//     .box-active #triangle-down .emm {
//         border-left: 198px solid transparent;
//         border-right: 198px solid transparent;
//     }
// }
// @media screen and ( max-width:1400px){
//     .emm {
//         border-left: 184px solid transparent;
//         border-right: 184px solid transparent;
//     }
//     .box-active #triangle-down .emm {
//         border-left: 186px solid transparent;
//         border-right: 186px solid transparent;
//     }
// }
@media ( max-width:1198px) and ( min-width:900px){
    .sec2-imgsec {
        width: 400px;
        height: auto;
        position: absolute;
        top: 80px;
        right: -70px;
    }
}
@media ( max-width:1222px) and ( min-width:1123px){
    .emm {
        border-left: 133px solid transparent;
    }
    .box-active #triangle-down .emm {
        border-left: 120px solid transparent;
    }
}
@media ( max-width:1350px) and ( min-width:1301px){
    .emm {
        border-left: 165px solid transparent;
    }
    .box-active #triangle-down .emm {
        border-left: 150px solid transparent;
    }
}
@media ( max-width:1480px) and ( min-width:1400px){
    .emm {
        border-left: 169px solid transparent;
    }
    .box-active #triangle-down .emm {
        border-left: 164px solid transparent;
    }
}
@media ( max-width:1025px) and ( min-width:901px){
    header.header-navbar .navbar-menu span p.inactive{
        padding: 5px;
        font-size: 12px;
    }
    header.header-navbar .navbar-menu span{
        margin-right: 0px;
    }
}
@media ( max-width:1222px) and ( min-width:901px){
    .bmw-one, .car-two, .unique-top{
        height: 160px !important;
    }
}
@media screen and ( max-width:1222px){
    #triangle-down{
        display: none;
    }
    .bmw-one, .car-two, .unique-top{
        position: unset;
        margin: auto;
        width: 100%;
        height: auto;
    }
    .sec3-content {
        margin-top: 0px;
    }
}
@media ( max-width:380px){
    .reg-h-con, .reg-com-h-con, .reg-cus{
        position: unset;
    background: transparent;
    padding: 0px;
    box-shadow: none;
    height: auto;
    }
    .reg-main > .reg-frm > .reg-h-con > p, .reg-com-con > .reg-com-h-con > .reg-com-h, .reg-cus > .reg-cus-h, .log-m-h{
        position: unset;
        padding-top: 20px;
        font-size: 25px !important;
    }
    .log-m-h{
        background-color: transparent;
        box-shadow: none;
    }
    .reg-frm, .log-main{
        padding-top: 20px;
    }
    .reg-com-con{
        padding-top: 20px;
    }
    .reg-cus-frm{
        padding-top: 20px;
    }
}