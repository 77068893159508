body {
    font-family: 'Poppins', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: normal !important;
    margin-bottom: 10px;
}

p {
    margin-top: 0;
    margin-bottom: 10px;
}

p.loginOr {
    position: relative;
    margin: 10px 0px;
}

p.loginOr:after {
    background: #c9bbbb none repeat scroll 0 0;
    bottom: 3.8px;
    content: "";
    height: 1px;
    left: -180px;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    width: 120px;
}

p.loginOr:before {

    background: #c9bbbb none repeat scroll 0 0;
    bottom: 3.8px;
    content: "";
    height: 1px;
    left: 40px;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    width: 120px;

}

.underline {
    color: #333333;
    font-family: 'Poppins', sans-serif !important;
    font-weight: bold !important;
    text-underline-position: under;
    margin-right: 10px;
    font-size: 32px;
    margin-top: 8px !important;
    margin-bottom: 10px !important;
}

.top {
    background-color: white !important;
    color: #333333 !important;
}

.menuicon {
    justify-content: flex-start;
}


.formText {
    font-family: 'Poppins', sans-serif !important;
    font-size: 14px !important;
    line-height: 1.5;
    // font-weight: bold !important;
}

.creditText {
    font-family: 'Poppins', sans-serif !important;
    font-size: 15px !important;
    line-height: 1.5;
    //font-weight: bold;
    font-weight: bold !important;
}

.TextField-without-border-radius fieldset {
    border-radius: 2;
}

.formTextflag {
    padding-bottom: 2px !important;
    font-family: 'Poppins', sans-serif !important;
    color: #0A2638;
    font-weight: bold !important;
    font-size: 20px !important;
}

.heading {
    font-size: 45px;
    color: white;
    text-align: center;
    // text-transform: uppercase;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
    padding: 10px 0px 0px 0px;
    text-align: center;
    margin: 10px 0px 0px 0px;
    letter-spacing: 3px;
}
.main-head{
    font-size: 50px;
    width: 440px;
    line-height: 60px;
    font-weight: 580 !important;
    letter-spacing: 0px;
}

.banner__items {
    height: 330px !important;
    background: url(../assets/images/s-banner.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #0A2638;
}

.searchbtn-holder {
    position: absolute;
    bottom: -24px;
    right: 30px;
    z-index: 1;
}

.searchbtn-holder button.MuiButtonBase-root.MuiButton-root.MuiButton-filledPrimary.btn-search {
    background-color: #47C9D9 !important;
    padding: 10px 20px;
    width: auto;
    height: auto !important;
    position: relative;
    left: 30px;
    top: 20px;
}

.searchbtn-holder button.MuiButtonBase-root.MuiButton-root.MuiButton-filledPrimary.btn-search p.buttonText {
    font-size: 16px !important;
    text-transform: none;
    color: #fff !important;
}


.menu {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: 'Tiempos' !important;
    font-weight: 'bold';
}

.stepLabel {
    font-family: 'Poppins', sans-serif  !important;
    font-size: 20px !important;
    color: #0A2638 !important;
}

header.header-navbar {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 10px;
    box-shadow: none;
}

header.header-navbar .navbar-menu {

    padding: 0px 20px;
}

header.header-navbar .navbar-menu span {
    margin-right: 10px;
}

header.header-navbar .navbar-menu span button {
    padding: 10px;
    line-height: normal;
    font-weight: bold;
    text-transform: none;
}

header.header-navbar .navbar-menu span p {
    font-family: 'Poppins', sans-serif !important;
    background-color: transparent;
    color: #ffffff;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
}

header.header-navbar .navbar-menu span p.active {
    font-family: 'Poppins', sans-serif !important;
    /* background-color: #47C9D9; */
    color: #FABA1C;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
    // border-bottom: 2px solid #47C9D9;
}

header.header-navbar .navbar-menu span p.inactive {
    font-family: 'Poppins', sans-serif !important;

    text-align: center;
    font-weight: normal;
    padding: 10px;
    cursor: pointer;
}

header.header-navbar .user-navbarmenu {
    display: flex;
    align-items: center;

}

.user-navbarmenu .loginmenu {

    font-family: 'Poppins', sans-serif !important;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 10px 26px;
    margin-right: 10px;
    border: 1px solid #32B4C4;
    background-color: #32B4C4;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500 !important;
}

.user-navbarmenu .registermenu {

    font-family: 'Poppins', sans-serif !important;
    text-align: center;
    display: flex;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #47C9D9;
    border-radius: 5px;
}


.user-navbarmenu .registermenu button.regiclass {
    padding: 0px;
    background-color: transparent;
    color: #000;
    text-transform: none;
    font-family: 'Poppins', sans-serif !important;
    text-align: center;
    font-weight: bold !important;
    cursor: pointer;
    line-height: normal;
}

.MenuText {
    background-color: #333333 !important;
    color: #FFFFFF !important;
}

.settingsactive {
    font-family: 'Poppins', sans-serif !important;
    background-color: #47C9D9 !important;
    color: white;
    text-align: center !important;
    font-weight: bold !important;
    padding: 6px !important;
    cursor: pointer;
}

.settingsinactive {
    font-family: 'Poppins', sans-serif !important;
    background-color: white !important;
    color: #333333;
    text-align: center;
    font-weight: bold !important;
    padding: 6px !important;
    cursor: pointer;
}

.Mui-active {
    color: #333333 !important;
    text-decoration: underline #47C9D9 2px;
    text-underline-position: under;
    font-size: 15px !important;
    font-family: 'Poppins', sans-serif !important;
}

.Mui-completed {
    font-size: 17px !important;
    font-family: 'Poppins', sans-serif !important;
}

.MuiStepLabel-label {
    font-weight: bold !important;
    color: #333333 !important;
    font-size: 12px !important;
    //font-size: 17px !important;
    font-family: 'Poppins', sans-serif !important;
    padding-top: 15%;

}

.vehiclename {
    font-family: 'Poppins', sans-serif !important;
    font-weight: bold !important;
    color: #333333;
    font-size: 25px !important;
}

.MuiStep-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.tripsdiv {
    border-top-left-radius: 20;
    border-top-right-radius: 20;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sidebar {
    height: 200px;
    width: 350px;
    background-color: #3c3c3c;
    overflow-y: auto;

    /* hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* hide scrollbar for chrome, safari and opera */
.sidebar::-webkit-scrollbar {
    display: none;
}

.inputRounded .MuiOutlinedInput-root {
    border-radius: 6px;
}

.MuiSelect-select {
    padding-right: 0px !important;
}

.react-tel-input .form-control {
    font-size: 12px !important;
    background: #FFFFFF;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 7px 14px 7px 45px !important;
    width: 99% !important;
    height: 32px !important;
}

.react-tel-input .selected-flag .flag {
    top: 50% !important;
}

// .react-datepicker {
//     position: absolute !important;
//     right: -290px !important;
//     width: max-content;
// }

.dateClassfeild {
    padding: 5 !important;

}

.texttimePicker {
    padding-top: 2 !important;
}

.texttimePicker input {

    padding: 0px;
    font-size: inherit;
}

.workflow_holder {
    position: relative;
}

.workflow_box {
    position: absolute;
    top: -130px;
}

.MuiGrid-root.MuiGrid-container.container_topspace {
    margin-top: 30px;
}

.workflow_box .workflow_box_inner {
    background: white;
    padding: 5px 0px;
    box-shadow: 0 0 15px 0px #0000004f;
    border-radius: 10px;
    width: fit-content;
}

.workflow_box .workflow_box_inner .workflow_icon {
    height: 50px;
    width: 50px;
}

.MuiStepper-root.MuiStepper-horizontal {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    align-items: stretch;
    align-content: center;

}

.MuiStep-root.MuiStep-horizontal {
    display: block;
    position: relative;
}

.MuiStepConnector-root.MuiStepConnector-alternativeLabel {}

.MuiStep-root.MuiStep-horizontal .MuiStepConnector-root.MuiStepConnector-alternativeLabel {
    top: calc(50% - 10px);
    left: calc(-40%);
    width: 60px;
}

.MuiStep-root.MuiStep-horizontal .MuiStepConnector-root.MuiStepConnector-alternativeLabel.Mui-active .MuiStepConnector-line {
    width: 100%;
    min-width: 100%;
    background-size: 130%;
    background-position: center;
    min-height: 32px;
    margin-top: -10px;
    border: none;
}

.MuiStep-root.MuiStep-horizontal .MuiStepConnector-root.MuiStepConnector-alternativeLabel.Mui-completed .MuiStepConnector-line {
    width: 100%;
    min-width: 100%;
    background-size: 130%;
    background-position: center;
    min-height: 32px;
    margin-top: -10px;
    border: none;
}

.MuiStep-root.MuiStep-horizontal .MuiStepConnector-root.MuiStepConnector-alternativeLabel .MuiStepConnector-line {

    width: 100%;
    margin: auto;
    margin-top: 8px;
    border-top: 1px solid #000;
}

.MuiStep-root.MuiStep-horizontal .MuiStepButton-root {
    padding: 10px 20px;
    margin: 0px;
    width: auto;
    display: block;
    height: auto;
}

.MuiStepLabel-root.MuiStepLabel-horizontal {
    display: flex;
}

.MuiStepLabel-root.MuiStepLabel-horizontal .MuiStepLabel-iconContainer {}

.workflow_box .workflow_box_inner .MuiStepLabel-root.MuiStepLabel-horizontal .MuiStepLabel-iconContainer>div {
    height: 25px;
    width: 25px;
    background-color: #ffffff;
    border-radius: 6px;
    padding: 5px;
}

.workflow_box .workflow_box_inner .MuiStepLabel-root.MuiStepLabel-horizontal .MuiStepLabel-iconContainer>div .workflow_icon {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.MuiStepLabel-root.MuiStepLabel-horizontal .MuiStepLabel-labelContainer .MuiStepLabel-label {
    margin: 0px;
    padding: 0;
}

#footer {
    padding: 55px 0px;
    padding-bottom: 0;
    color: white;
    /* font-size: 13px; */
    background-color: #252129;
    position: relative;
    overflow: hidden;
    margin-top: 50px;
}

#footer p {
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: normal;
}

#footer p.link {
    cursor: pointer;
}

.column_title {
    margin-top: 0px;
    margin-bottom: 10px;
    color: #47C9D9;
    font-weight: 600 !important;
}

#footer p a {
    // margin-bottom: 5px;
    font-size: 13px;
    display: inline-block;
    text-decoration: none;
    color: #47c9d9;
}

.logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

#footer .copy-right {
    background: black;
    padding: 10px;
    margin-top: 50px;
}

#footer .copy-right p {
    color: white;
    text-align: center;
    font-size: 12px;
    margin: 0;
}

.MuiModal-root .MuiBox-root {
    border: none;
    background-color: transparent;
}

.MuiModal-root .MuiButtonBase-root.MuiIconButton-root {
    background-color: #ffffff;
    border-radius: 100px;
    padding: 0px 5px;
}

.shadow_box {
    background: white;
    padding: 20px;
    padding-top: 90px;
    box-shadow: 0 0 15px 0px #0000004f;
    border-radius: 20px;
    margin: auto;

    position: relative;
}

.shadow_box .box_title {
    position: absolute;
    left: 0;
    top: 20px;
    border-radius: 0px 20px 20px 0px;
    padding: 10px 20px;
    background-color: #47C9D9;

}

.shadow_box .box_title p,
.shadow_box .box_title p.MuiTypography-root {
    color: #000;
    font-family: 'Poppins', sans-serif !important;
    font-size: 20px !important;
}

.shadow_box .box_title p.MuiTypography-root .usermenu button.MuiButtonBase-root {
    font-family: 'Poppins', sans-serif !important;
    background-color: white;
    color: #333333;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
}

.usermenu button.MuiButtonBase-root p.MuiTypography-root {
    font-family: 'Poppins', sans-serif !important;
    background-color: white;
    color: #333333;
    text-align: center;
    font-weight: 600;
    padding: 10px;
    cursor: pointer;
}

.usermenu p.MuiTypography-root {
    font-family: 'Poppins', sans-serif !important;
    background-color: white;
    color: #333333;
    text-align: center;
    font-weight: 600;
    padding: 10px;
    cursor: pointer;
    line-height: 1.5;
    letter-spacing: 0em;
    font-size: 16px;
}

button.primary-btn,
button.MuiButtonBase-root.MuiButton-filledPrimary {
    background-color: #47C9D9 !important;
    color: #000 !important;
    border-radius: 6px !important;
    padding: 10px 20px !important;
    height: auto !important;
}

button.primary-btn:hover,
button.MuiButtonBase-root.MuiButton-filledPrimary:hover {
    background-color: #f0ed33 !important;
    color: #000000;
    border-radius: 6px !important;
    padding: 10px 20px !important;
    height: auto !important;
}

button.primary-btn .MuiTypography-root,
button.MuiButtonBase-root.MuiButton-filledPrimary .MuiTypography-root {
    color: #000;
}

button.primary-btn:hover .MuiTypography-root,
button.MuiButtonBase-root.MuiButton-filledPrimary:hover .MuiTypography-root {
    color: #000000;
}

.MuiFormGroup-root .MuiTypography-root {
    font-size: 14px !important;
    top: 10px !important;
    left: 15px !important;
    padding: 5px 0px;
}

.MuiFormGroup-root .MuiFormControl-root.MuiTextField-root,
.MuiFormGroup-root .react-datepicker__input-container .MuiFormControl-root.MuiTextField-root {
    background-color: rgb(255, 255, 255) !important;
    // border: 1px solid #47C9D9 !important;
    border-radius: 10px !important;
    width: auto !important;
    height: auto !important;
    padding: 10px !important;
    padding-top: 10px !important;
    display: block;
    box-shadow: -1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}

.MuiFormGroup-root .MuiFormControl-root {
    background-color: rgb(255, 255, 255) !important;
    // border: 1px solid #47C9D9 !important;
    border-radius: 10px !important;
    width: auto !important;
    height: auto !important;
    padding: 10px !important;
    padding-top: 10px !important;
    display: block;
    box-shadow: -1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}

.MuiFormGroup-root .MuiFormControl-root .MuiInputBase-root.MuiInput-root {
    width: 100%;
    border: none !important;
}

.MuiFormGroup-root .react-tel-input {
    background-color: rgb(255, 255, 255) !important;
    // border: 1px solid #47C9D9 !important;
    border-radius: 10px !important;
    width: auto !important;
    height: 30px !important;
    padding: 10px !important;
    padding-top: 10px !important;
    display: block;
    box-shadow: -1px 1px 15px 0px rgba(0, 0, 0, 0.2);
}

.MuiFormGroup-root .react-tel-input .flag-dropdown {
    border: none;
    border-right: 1px solid #47C9D9;
}

.MuiFormGroup-root .react-tel-input .form-control {
    border: none;
}

button.MuiButtonBase-root.MuiTab-root {
    color: #000000;
    border-radius: 0px 15px 0px 0px;
    margin: 5px 5px 5px 0px;
    background-color: #D9D9D9;
    padding: 10px 20px;
    height: 30px !important;
    text-transform: capitalize;
    min-height: auto !important;
    line-height: normal;
    max-width: 100% !important;
}

button.MuiButtonBase-root.MuiTab-root.tabheight {
    color: #ffffff;
    border-radius: 6px;
    margin: 5px 5px 5px 0px;
    background-color: #333333;
    padding: 10px 20px;
    height: 30px !important;
    text-transform: capitalize;
    min-height: auto !important;
    line-height: normal;
    max-width: 100% !important;
}

button.MuiButtonBase-root.MuiTab-root.Mui-selected {
    color: #ffffff;
    background-color: rgba(58, 219, 239, 0.81);
}

.MuiTabs-scroller .MuiTabs-indicator {
    display: none !important;
}

.MuiToggleButtonGroup-root.toggleRadio {
    margin-bottom: 10px;
}

.MuiToggleButtonGroup-root.toggleRadio button.MuiToggleButton-root {
    background-color: transparent;
    position: relative;
    padding-left: 30px;
    font-size: 14px !important;
    height: auto;
    line-height: normal;
    padding-top: 7px;
    padding-bottom: 7px;
}

.MuiToggleButtonGroup-root.toggleRadio button.MuiToggleButton-root:after {
    content: '';
    position: absolute;
    top: 6px;
    left: 4px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: white;
    border: 2px solid #333333;
}

.MuiToggleButtonGroup-root.toggleRadio button.MuiToggleButton-root.Mui-selected {
    background-color: transparent;
    color: #000000;
}

.MuiToggleButtonGroup-root.toggleRadio button.MuiToggleButton-root.Mui-selected:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 8px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #000000;
    border: none;
    z-index: 1;
}



.vehicleBox {
    display: flex;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ddd;
    flex-direction: row;
    justify-content: space-between;
    // flex-wrap: wrap;
}

.vehicleBox_img {
    width: 100%;
    height: 200px;
}

.vehicleBox_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.vehicleBox_details {
    width: 100%;
    padding: 10px 10px;
    // border-top: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    background: #47c9d924;
    border-radius: 0px 10px 10px 0px;
}

.sum_listitem {
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 1%;
    margin-bottom: 10px;
    box-shadow: 0px 5px 12px #ddd;
    padding: 15px 20px;
    background: #47c9d9;
    border-radius: 8px;
}

.img_icon {
    background-color: #ffffff;
    height: 30px;
    width: 30px;
    padding: 4px;
    border-radius: 6px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.img_icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;

}

.listitem_value {
    display: block;
}

.descriptionList {

    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    width: fit-content;

}

.descriptionList .descriptionItem {
    display: flex;
    align-items: center;
    padding-right: 5px;
}

.descriptionList .descriptionItem .descriptionItemImg {
    width: 20px;
    height: 20px;
    object-fit: contain;
    padding: 8px;
    background-color: #ffffff;
    border-radius: 6px;
    margin: 0px 5px;
}

.descriptionList .descriptionItem p.descriptionText {
    padding: 0px !important;
    color: #000 !important;
    font-size: 14px !important;
    padding-right: 10px !important;
}

.description_holder {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    // justify-content: space-between;
    align-items: flex-start;
}

.vehicle_price_holder {
    padding-left: 20px;
}

.vehicle_price_holder p.pricetext {
    font-size: 20px !important;
    color: #ff0000;
    font-family: 'Poppins', sans-serif !important;
    font-weight: bold;
    font-weight: bold !important;
    text-align: left;
}

.tripsummary_holder {}

.sum_list {
    background-color: #fff;
    // border: 1px solid #ddd;
    border-radius: 10px;
    margin-top: 15px;
    padding: 10px;
}

.dropdown_formcontrol {
    background-color: white !important;
    // border: 1px solid #47C9D9 !important;
    border-radius: 10px !important;
    width: auto !important;
    height: auto !important;
    padding: 10px !important;
    padding-top: 10px !important;
    display: block;
    box-shadow: -1px 1px 15px 0px rgba(0, 0, 0, 0.25) !important;
}

.texttimePicker {}

button.newbooking {
    color: white;
    background-color: rgb(51, 51, 51);
    border-radius: 6px;
    height: 30px;
    padding: 5px 10px;
}

.username_menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px;
    border-radius: 0px 10px 10px 0px;
    background-color: #47C9D9;
}

.username_menu .MuiAvatar-root {
    margin-right: 10px;
    background-color: #FAB104;
    height: 30px;
    width: 30px;
}

.booking_box {
    border-radius: 30px 30px 0px 30px !important;
    background-color: #ffffff;
    // border: 1px solid #47C9D9;
}

.wallet_box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

.wallet_amt {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 10px;
    width: 40%;
    background-color: #47C9D9;
    align-items: center;
}

.topup_holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.MuiList-root.MuiList-padding.MuiMenu-list {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    padding: 10px;
    justify-content: center;
    flex-wrap: wrap;

}

.MuiList-root.MuiList-padding.MuiMenu-list>div {
    width: 100%;
}

.MuiList-root.MuiList-padding.MuiMenu-list>div .MuiSvgIcon-root {
    border-radius: 50px !important;
}

.location_point {
    background-color: #fff;
    // border: 1px solid #ddd;
    border-radius: 10px;
    margin-top: 0;
    padding: 10px;
}

.location_point p.loctext {
    text-align: center;
    margin-bottom: 10px;
    background-color: #47C9D9;
    border-radius: 6px;
    padding: 5px 0px;
    font-weight: bold;
}

.MuiCardContent-root.card_content {
    border-radius: 6px;
    padding: 0px;
}

.container_topspace {
    margin-top: 30px !important;
}



.triptype_title {
    background-color: #ffffff;
    color: #000;
    height: 30px;
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: bold;
    font-family: 'Poppins', sans-serif !important;
    padding: 5px;
    position: relative;
}

.location_startpoint {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 90%;
}

.location_stoppoint {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 90%;
}

.location_startpoint .location_innerbox,
.location_stoppoint .location_innerbox {
    width: 100%;
}

.location_midpoint {
    border: none;
    margin-left: 10px;
    min-height: 2px;
    width: 2px;
    height: 40px;
    background-color: #ffffff;
}

.mainstop {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    align-items: baseline;
}

.location_spot {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
    width: 100%;
    justify-content: space-around;
    background: #47c9d9;
    padding: 10px 0px;
    box-shadow: 0px 5px 12px #ddd;
    border-radius: 8px;
    flex-direction: column;
}

.location_innerbox {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
}

// .location_innerbox .img_icon {
//     margin: 10px auto;
// }

.triptype_name {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.location_innerbox .loctext {

    font-size: 12px;
    text-align: center;
}

.total_fare {
    text-align: right;
    padding: 10px;
    background-color: #ff0000;
    color: #fff;
}

.total_fare p {
    font-size: 22px;
}

.contactimg_wrapper {}

.contactimg_wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.companyname_head {
    font-size: 36px;
    margin: 0px;
    margin-bottom: 10px;
}

.contactinfo_wrapper {
    padding: 40px 20px;
}

.section_heading {
    font-size: 36px;
    margin: 0;
    margin-bottom: 10px;
}

.leftside_textholder {
    padding: 40px;
}

.rightside_textholder {
    padding: 40px;
}

.leftside_imgholder {}

.leftside_imgholder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.rightside_imgholder {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.rightside_imgholder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.about_sec4 {
    padding: 10px 0px;
}

.about_sec4 .section_heading {
    text-align: center;
}

.box_content {
    text-align: center;
    padding: 20px;
}

.icon_img {
    width: 80px;
    margin: 10px auto;
}

.icon_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

ul.service_point li {
    font-size: 16px;

}

.box_heading {
    font-size: 26px;
    margin: 10px 0px;
}

.MuiListItemText-root button.MuiButtonBase-root.MuiButton-root {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 16px !important;
    padding-right: 16px !important;
    font-weight: normal !important;
    text-transform: none;
    font-size: 16px;
}

.full_contentholder {
    padding: 20px 40px;
}

.row_title {
    font-size: 24px;
}

.row_para {
    margin-bottom: 20px;
    text-align: justify;
}

.row_list {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
}

.row_list li {}

.readmore_link {
    cursor : pointer;
    color: #47C9D9;
}

.our_service{
    padding: 40px
}
.contact-list{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.e-mail{
    display: flex;
    align-items: center;
}
.e-mail{
    color: #fff;
    text-decoration: none;
    padding: 0px 5px;
}
a.e-mail svg{
    padding: 0px 5px;
}
.phone{
    display: flex;
    align-items: center;
}
.phone{
    color: #fff;
    text-decoration: none;
    padding: 0px 5px;
}
a.phone svg{
    padding: 0px 5px;
}
.social-m a{
    padding: 0px 8px 0px 0px;
}
.search-box{
    /* background-color: #fff; */
    padding: 30px 0px 10px 0px;
    border-radius: 30px 30px 0px 30px;
    position: relative;
    top: 30px;
    width: 100%;
    // box-shadow: -1px 1px 15px 0px rgba(0, 0, 0, 0.25);
}
// my changes
.MuiFormGroup-root .MuiFormControl-root.MuiTextField-root{
    border: none !important;
    border-radius: 15px;
    background: #FFF;
    padding-top: 20px;
    padding-left: 25px;
    box-shadow: -1px 1px 15px 0px rgba(0, 0, 0, 0.25)
}
.searchbtn-holder button.MuiButtonBase-root.MuiButton-filledPrimary{
    padding: 15px 60px !important;
    border-radius: 0px 0px 10px 10px !important;
}
.search-con .css-19midj6{
    padding: 16px 16px 25px 16px;
    box-shadow: -1px 1px 15px 0px rgba(0, 0, 0, 0.25);
    border-radius: 30px 30px 0px 30px;
}
.circle-one {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 40px solid #d9d9d9;
    position: absolute;
    left: -130px;
}
.circle-two {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 40px solid #d9d9d9;
    position: absolute;
    right: -130px;
}
.log-con{
    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    padding-top: 30px;
}
.log-main .shadow_box .box_title{
    background-color: transparent;
}
.log-m-h{
    position: absolute;
    top: -260px;
    left: 3px;
    text-align: center;
    background: #47C9D9;
    padding: 10px;
    border-radius: 290px;
    width: 94%;
    height: 12%;
    padding-top: 290px;
    font-size: 23px !important;
    font-weight: 500 !important;
    box-shadow: 0px 7px 20px #47c9d980;
}
.log-main{
    background: white;
    padding: 20px;
    padding-top: 90px;
    box-shadow: 0 0 15px 0px #0000004f;
    border-radius: 20px;
    margin: auto;
    position: relative;
    overflow: hidden;
}
.log-con > div > .btn_login{
    background-color: #47C9D9 !important;
}
.log-holder{
    margin: 0px auto;
    width: 70% !important;
    padding-Top: 0px;
    position: relative;
    left: 0px;
    top: -60px;
}
.vec-res-h{
    text-align: left;
    padding-left: 20px;
}
.facebook-i{
    width: fit-content;
    border-radius: 8px;
    margin-top: 0px;
    vertical-align: middle;
    text-align: center;
    // background-color: #47C9D9;
    border: 1px solid transparent;
}
// .facebook-i:hover{
//     border: 1px solid #fff;
//     background-color: transparent;
// }
.vehicle_price_holder{
    display: flex;
    align-items: center;
    padding-top: 30px;
}
.vehicle_price_holder .btn-holder{
    margin: 0px 10px;
}
.navbar-menu{
    align-items: center;
    justify-content: center;
}
.search-p{
    width: 100%;
    max-width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.card-b{
    border: 1px solid #ddd;
}
.m-logo{
    width: 80px;
    height: auto;
}
.log-p-l{
    width: 90px;
    height: auto;
    cursor: pointer;
}
.pass-s{
    float: right;
}
.padd-r-l{
    padding-left: 96px;
    padding-right: 96px;
}
.user-d{
    width: 500px !important;
}
.mar-r-l{
    margin-left: 96px !important;
    margin-right: 96px !important;
}
.srh-f-w{
    justify-content: flex-start !important;
}
.drp-u{
    width: 200px !important;
}
.abt-c p{
    padding-top: 10px;
}
.contact_address_holder a{
    text-decoration: none;
    color: #000;
}
.contact_address_holder p{
    padding-top: 10px;
}
.serv-row{
    display: flex;
}
.serv-img-con {
    width: 28%;
    margin: 0px auto;
    border-radius: 15px;
    overflow: hidden;
    background: #fff;
    padding: 10px;
    box-shadow: 0px 1px 5px #cecece;
}
.serv-img-con img{
    width: 100%;
}
.serv-row{
    margin-top: 40px;
}
.sev-p{
    background-color: #ffffff;
}
.sev-p h4, .sev-p p{
    margin: 0px;
    padding-top: 10px;
}
.sr-img-p{
    border-radius: 15px 15px 0px 0px;
    overflow: hidden;
}
.sev-p h4{
    font-size: 20px;
    font-weight: 700 !important;
    color: #27c2d5;
}
.sev-p p{
    padding-bottom: 10px;
}
.contact_address_holder svg{
    padding-right: 10px;
}
.social-m{
    display: flex;
}
.social-m a img{
margin-top: 30px;
}
.usermenu{
    background-color: #fff;
    align-items: center;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    left: -150px !important;
}
.d-f{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.stng-f{
    font-size: 1rem;
}
.usermenu > div> button#basic-button{
    font-family: 'Poppins', sans-serif !important;
    background-color: white;
    color: #333333;
    text-align: center;
    font-weight: 600 !important;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
}
.log-f{
    font-family: 'Poppins', sans-serif !important;
    background-color: white;
    color: #333333;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
}
.location-p-f{
    display: flex;
    overflow: hidden;
}
.location-p-f .location_innerbox{
    padding: 10px 0px;
}
.tot-f-bg{
    padding: 10px;
}
.close-btn{
    background-color: #333 !important;
}
#footer .logo{
    width: 80%;
    margin: auto;
}
.footer .logo img{
    width: 100%;
}


@media screen and (max-width:480px) {
    .username_menu {
        padding: 5px 5px
    }

    .username_menu .MuiAvatar-root {
        width: 25px;
        height: 25px;
        margin-right: 5px;
    }

    .username_menu .headerstyle {
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 50px;
        overflow: hidden;
    }

    .banner__items {
        height: 200px !important;
    }

    .leftside_textholder {
        padding: 20px;
    }

    .rightside_textholder {
        padding: 20px;
    }

    .section_heading {
        font-size: 22px;
    }

    p {
        font-size: 12px;
    }

    ul.service_point li {
        font-size: 12px;

    }

    .MuiModal-root .MuiBox-root {
        padding: 0px;
        width: 80%;
    }
    .contact-list{
        flex-wrap: wrap;
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
    }
    .heading{
        padding: 0px 0px 0px 0px;
        text-align: center;
    }
    .sub-box .sec2-content{
        margin-top: 0px;
    }
    .bg-gray{
        padding: 0px 0px 150px 0px;
    }
    // .sub-box{
    //     margin: 0px;
    //     padding: 0px;
    // }
    // .sec2-content h2{
    //     margin: 0px;
    // }
    // .sec2-content .read-more button{
    //     margin-bottom: 0px;
    // }
    .sub-box .sec2-imgsec {
        width: 100%;
        height: auto;
        position: absolute;
        top: -90px;
        right: 0px;
    }
    .bg-white.fleet > div > .m-t-high{
        flex-direction: column;
    }
    .bg-white.fleet > div > .m-t-high > .squre{
        max-width: 90% !important;
        margin-top: 120px !important;
    }
    .log-holder{
        width: 90% !important;
    }
    .log-m-h{
        width: 92%;
        height: 17%;
    }
    .reg-h-con{
        width: 530px !important;
    }
    .reg-main > .reg-frm > .reg-h-con > p{
        left: 220px !important;
        font-size: 18px !important;
    }
    .reg-com-h-con{
        width: 520px !important;
    }
    .reg-com-con > .reg-com-h-con > .reg-com-h{
        left: 205px !important;
        font-size: 18px !important;
    }
    .reg-cus{
        width: 520px !important;
    }
    .reg-cus > .reg-cus-h{
        left: 200px !important;
    }
    .search-box{
        padding: 0px 30px 30px 30px;
    }
    .main-head{
        margin-top: 0px !important;
        padding: 0px 0px 0px 50px !important;
        position: absolute;
        top: 130px;
    }
    .m-logo{
        width: 113px;
        height: auto;
    }
    .homeBackground{
        height: 800px !important;
    }
    .vehicleBox{
        flex-direction: column;
        overflow: hidden;
        padding: 0px;
    }
    .rlt-con{
        flex-direction: column-reverse !important;
    }
    .menuicon{
        color: #fff;
    }
    .padd-r-l{
        padding-left: 10px;
        padding-right: 10px;
    }
    .search-box{
        top: 10px;
    }
    .serv-row{
        flex-direction: column !important;
    }
    .serv-img-con {
        width: 90%;
        margin: 20px auto;
    }
    .point-h {
        min-height: 30px !important;
        margin-left: 0px;
        margin-bottom: 10px;
    }

   
}
@media screen and (max-width:767px) {
    .footer .logo{
        width: 80%;
        margin: auto;
    }
    .footer .logo img{
        width: 100%;
    }
}
@media (max-width:1127px) and (min-width:900px) {
    .vehicleBox{
        flex-direction: column;
    }
    .vehicleBox_img{
        margin: auto;
    }
}
@media (max-width:690px) and (min-width:480px) {
    .sec2-imgsec{
        width: 500px !important;
    }
}
@media screen and (max-width:900px) {
    .menuicon {
        color: #fff;
    }
}
@media screen and (max-width:361px) {
    .shadow_box .box_title p, .shadow_box .box_title p.MuiTypography-root{
        font-size: 16px;
    }
}
@media screen and (max-width:1200px) {
    .main-head{
        width: auto;
    }
}