.approved
{
color: #408901 !important;
}
.rejected
{
    color: #FF0000 !important;
}
.uploadIcon
{
    color: white !important;
    background-color: #47C9D9 !important;
    border-radius: 1px !important;
    font-size: 20px !important;
    //margin-right: -5px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MuiDataGrid-cell{
    font-family: 'Poppins', sans-serif !important;
    font-size: 12px;
}