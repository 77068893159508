.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 15px!important;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  font-size: 18px;
  font-weight: bold !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.MuiTypography-root
  {
  /* font-family: 'Nunito'!important; */
  letter-spacing: 0em !important;
  color: #333333;
  
  }

  .css-hip9hq-MuiPaper-root-MuiAppBar-root
  {
    box-sizing: inherit !important;
  }

  .MuiOutlinedInput-root{
    border-radius: 20px !important;
  }


  .react-datepicker {
    position: absolute !important;
    right: unset !important;
    width: max-content;
}

.MuiSnackbar-root {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

.Toastify__toast-container--top-center{
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}