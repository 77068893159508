.permissionText
{
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    font-size: 38px !important;
    color: #333333;
    display: flex ;

}
.permiUnderline
{
    font-size: 38px;
    padding-left: 1.5em;
    color: #333333;
    display: flex ;
    font-family: 'Nunito';
    text-decoration: underline #47C9D9;
    text-underline-position: under;
    
}
.yes
{
color: #71E97D !important;
width: 85px ;
height: 45px ;
}
.no
{
    color: #FF6E6E !important;
    width: 85px ;
    height: 45px ;
}

.MuiTableCell-root{
    font-size: 14px !important;
   // font-weight: bold !important;
    height: 10px !important;
    padding: 0px !important;
    margin: 0px;
}

.tableHead {
    font-size: 12px;
    font-weight: bold !important;
    font-family: 'Nunito';
    height: 40px !important;
}