.totalText
{
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    font-size: 16px !important;
    color: #2B2B2B;
}
.arrivalText
{
    
    font-size: 20px !important;
    font-weight: bold !important;
    padding-bottom: 12%;
    padding-top: 3%;
    padding-left: 1%;
}
.iti{
    width: 100%;
}
.buffer-w button{
    margin: 0px 10px;
}
.date-t input{
    padding-bottom: 0px;
    height: 30px;
    padding-top: 0px;
}
.image-up input{
    height: 30PX;
    padding-top: 0px;
    padding-bottom: 0px;
}