.forgot {
    background: white;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    padding-top: 8em;
}
.desText
{
    //padding-top: 2em;
    font-weight: 600 !important;
    font-family: 'Nunito' !important;
    font-size: 12px !important;
    text-align: center;
    padding-bottom: 20px;
}